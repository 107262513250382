export default {
  methods: {
    addNameAndValidate(event) {
      if(this.validateImage(event.target.files[0])) {
        this.file = event.target.files[0];
        this.fileName = this.file ? this.file.name : '';
        this.$emit('image-uploaded', { file: this.file, index: this.index });
      }
    },
    validateImage(content) {
      if (content && this.maxSize && content.size > this.maxSize) {
        this.invalidFeedback = this.$t('messages.error.fileSize', { size: this.maxSize/1000000 });
        return false;
      } else 
        return true;
    },
    deleteFile() {
      this.file = null;
      if(this.record) {
        this.record.image = null;
      } else if(this.question) {
        this.question.image = null;
      }
      this.$emit('image-deleted', this.index);
    }
  }
};
