<template>
  <div>
    <Breadcrumbs :main="$tc('models.quiz.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <QuizAttr 
        :record="record" 
        :quizError="quizError"
        @image-uploaded="saveQuizFile"
        @image-deleted="deleteImage" 
      />
      <div v-for = "(question, index) in record.questions.items" :key="index">
        <b-row v-if="!question.destroy">
          <QuestionAttr 
            :question="question"
            :index="index"
            :errors="questionsErrors[index]"
            @remove-question="removeQuestion(index)"
            @image-uploaded="saveFile"
            @image-deleted="deleteImage"
          />
        </b-row>
      </div>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col>
          <button type="submit"
                  :disabled="!validQuiz || formState"
                  class='btn btn-primary btn-block' @click="validateForm">
            <span>{{ submitButton.label }}</span>
          </button>
        </b-col>

        <b-col>
          <button type="submit" class='btn btn-primary btn-block' @click="addNewQuestion">
            <span> {{ $t('actions.addQuestion') }} </span>
          </button>
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import QuestionAttr from './question';
import {Question} from 'models/question';
import FormsMixin from 'mixins/FormsMixin';
import QuizAttr from './quiz-attributes';

export default {
  name: 'QuizForm',
  components: {
    QuestionAttr,
    QuizAttr
  },
  data() {
    return {
      submitButton: {
        label: this.$t('actions.submit'),
        block: true
      },
      modelName: 'Quiz',
      questionsErrors: [],
      quizError: '',
      files: {},
      file: null,
      formSubmitted: false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      record: 'quiz'
    }),
    validQuiz() {
      return ( this.record.title && !!this.record.title.trim() ) &&
             (!!this.record.instructions);
    },
    formState() {
      return this.formSubmitted;
    }
  },
  methods: {
    ...mapActions({
      prepareNewQuiz: 'prepareNewQuiz', 
      getQuiz: 'getQuiz',
      createRecord: 'createQuiz',
      updateRecord: 'updateQuiz',
      getRecord: 'getQuiz',
      constructQuizData: 'constructQuizData'
    }),
    addNewQuestion() {
      this.record.questions.items.push(new Question());
    },
    removeQuestion(index){
      const question = this.record.questions.items[index];
      question.destroy = true;

      this.record.questions.items.splice(index, 1, question);
    },
    onSubmition(){
      const recordData = this.constructQuizData();
      this.processSubmission(recordData);
    },
    saveFile(data) {
      this.files[data.index] = data.file;
    },
    saveQuizFile(data) {
      this.file = data.file;
    },
    deleteImage(index) {
      this.files[index] = null;
    },
    deleteQuizFile() {
      this.file = null;
    },
    validateForm() {
      this.formSubmitted = true;
      this.questionsErrors = [];
      this.quizError = '';

      let questionLength = this.record.questions.items.length;
      let errorsFlag = false;
      let deleteAllQuestionsFlag = true;

      for(let i = 0; i < questionLength; i++) {
        let questionErrors = [];
        let question = this.record.questions.items[i];
        let correcAnswers = 0;
        let choicesLength = question.choices.items.length;
        deleteAllQuestionsFlag = question.destroy && deleteAllQuestionsFlag;
        let deleteAllChoices = true;

        if(question.destroy)
          continue;

        if(!question.title)
          questionErrors.push(this.$t('messages.error.quiz.questionTitle'));

        for(let j = 0; j < choicesLength; j++) {
          let choice = question.choices.items[j];
          deleteAllChoices = choice.destroy && deleteAllChoices;

          if(choice.destroy)
            continue;

          if(!choice.title)
            questionErrors.push(this.$t('messages.error.quiz.choiceTitle', {
              index: j + 1
            }));

          if(choice.correct)
            correcAnswers++;
        }

        if(choicesLength === 0 || deleteAllChoices)
          questionErrors.push(this.$t('messages.error.quiz.choicesCount'));

        if(correcAnswers === 0)
          questionErrors.push(this.$t('messages.error.quiz.correctChoices'));

        if(questionErrors.length > 0)
          errorsFlag = true;

        this.questionsErrors.push(questionErrors);
      }
      
      if(deleteAllQuestionsFlag) {
        this.quizError = this.$t('messages.error.quiz.questionsCount');
        return;
      }

      if(!errorsFlag) 
        this.onSubmition();
      else
        this.formSubmitted = false; 
    },
    constructQuizData() {
      const recordData = new FormData();

      recordData.append('quiz[title]', this.record.title);
      recordData.append('quiz[instructions]', this.record.instructions);
      recordData.append('quiz[author_id]', this.currentUser['id']);

      if(this.file)
        recordData.append('quiz[image]', this.file);
      else if(!this.record.image)
        recordData.append('quiz[_destroy_image]', 1);
  
      let questionIndex;
      let choiceIndex;
      let questions = this.record.questions ? this.record.questions.items : [];
  
      for(questionIndex = 0; questionIndex < questions.length; questionIndex++) {
        let question = questions[questionIndex];
  
        if(question['id'])
          recordData.append(`quiz[questions_attributes][${questionIndex}][id]`, question['id']);
  
        recordData.append(`quiz[questions_attributes][${questionIndex}][title]`, question['title']);
        recordData.append(`quiz[questions_attributes][${questionIndex}][comment]`, question['comment'] || '');
        recordData.append(`quiz[questions_attributes][${questionIndex}][_destroy]`, question['destroy']);

        if(this.files[questionIndex])
          recordData.append(`quiz[questions_attributes][${questionIndex}][image]`, this.files[questionIndex]);
        else if(!question.image)
          recordData.append(`quiz[questions_attributes][${questionIndex}][_destroy_image]`, 1);
  
        let choices = question['choices'] ? question['choices'].items : [];
        for( choiceIndex = 0; choiceIndex < choices.length; choiceIndex++) {
          let choice = choices[choiceIndex];
  
          if(choice['id']) {
            recordData.append(
              `quiz[questions_attributes][${questionIndex}][choices_attributes][${choiceIndex}][id]`,
              choice['id']);
          }
  
          recordData.append(
            `quiz[questions_attributes][${questionIndex}][choices_attributes][${choiceIndex}][title]`,
            choice['title']);
  
          recordData.append(
            `quiz[questions_attributes][${questionIndex}][choices_attributes][${choiceIndex}][model_answer]`,
            choice['correct']);
            
          recordData.append(
            `quiz[questions_attributes][${questionIndex}][choices_attributes][${choiceIndex}][_destroy]`,
            choice['destroy']);
        }
      }
      return recordData;
    },
    bindValue(value, field) {
      this.$set(field, 'value', value);
      this.record[field] = value;
    },
    bindValid(value, field) {
      this.$set(field, 'valid', value);
      this.$forceUpdate();
    }
  },
  mounted() {
    if(this.$route.params.id) {
      this.prepareForm();
    } else {
      this.prepareNewQuiz();
    }
  },
  mixins: [FormsMixin]
};
</script>
