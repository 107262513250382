<template>
  <b-row>
    <b-col>
      <b-card class="mt-4">
        <b-card-body>

          <div v-if="!!quizError" class="mb-3">
            <ul>
              <li>
                <p class="text-danger">{{ quizError }}</p>
              </li>
            </ul>
          </div>

          <b-row class="mb-3">
            <b-col>
              <label :for="record.title">{{ $t('models.quiz.attributes.title') }} *</label>
              <b-form-input 
                v-model="record.title">
              </b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <label :for="record.instructions">{{ $t('models.quiz.attributes.instructions') }} *</label>
              <vue-editor
                v-model="record.instructions" 
                :placeholder="$t('views.placeholders.quiz.instructions')">
              </vue-editor>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-input-group v-if="!record.image" class="custom-file" >
                <b-form-file class="custom-file-input" id="quiz-customFile"
                  accept="image/*" 
                  @change="addNameAndValidate"></b-form-file>
                <label class="custom-file-label" for="quiz-customFile">{{ fileName }}</label>
              </b-input-group>
              <div v-else class="text-center">
                <b-card-img :alt="record.image.filename" class="thumb"
                            :src="getImageURL(record.image.url)"></b-card-img>
                <br>
                <a href="javascript:void(0);" @click="deleteFile">{{ $t('actions.delete') }}</a>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import ImageInputMixin from 'mixins/ImageInputMixin';

export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    quizError: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: {},
      fileName: ''
    };
  },
  mixins: [ImageInputMixin],
  components: {
    VueEditor
  }
};
</script>
