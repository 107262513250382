<template>
  <b-col>
    <b-card class="mt-4">
      <b-card-header class="pt-0">
         <b-row>
           <b-col cols="11" class="pl-0">
             <b-card-title> {{ $tc('models.question.entity', 1) }}# {{ index + 1 }} </b-card-title>
           </b-col>
           <b-col class="pr-0">
             <span class="custom__remove" @click="removeQuestion"> ❌</span>
           </b-col>
         </b-row>
      </b-card-header>
      <b-card-body>
        <div v-if="errors.length" class="mb-3">
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              <p class="text-danger">{{ error }}</p>
            </li>
          </ul>
        </div>
        <b-row class="mb-2">
          <b-col cols="9">
            <b-form-input 
              v-model="question.title" 
              :placeholder="$t('views.placeholders.quiz.questionTitle')">
            </b-form-input>
          </b-col>
          <b-col>
            <button type="submit" class="btn btn-primary btn-block" @click="addChoice">
              {{ $t('actions.addChoice') }}
            </button>
          </b-col>
        </b-row>

        <hr class="dashed">

        <div v-for="(choice, index) in question.choices.items" :key="index">
          <b-row class="mb-2" v-if="!choice.destroy" >
            <b-col>
              <b-input-group class="mb-2 input-group">
                <b-input-group-prepend is-text>
                  <input type="checkbox" 
                          v-model="question.choices.items[index].correct" 
                          aria-label="Checkbox for following text input">
                </b-input-group-prepend>
                <b-form-input aria-label="Text input with checkbox" 
                              v-model="question.choices.items[index].title" 
                              :placeholder="$t('views.placeholders.quiz.option') + ` ${index + 1}`">
                </b-form-input>
                <b-input-group-append is-text>
                  <span class="custom__remove clickable" @click="removeChoice(index)"> ❌</span>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <hr class="dashed">

        <b-row class="mb-3">
          <b-col>
            <b-input-group v-if="!question.image" class="custom-file" >
              <b-form-file class="custom-file-input" :id="`question${index}-customFile`"
                accept="image/*" 
                @change="addNameAndValidate">
              </b-form-file>
              <label class="custom-file-label" :for="`question${index}-customFile`">{{ fileName }}</label>
            </b-input-group>
            <div v-else class="text-center">
              <b-card-img :alt="question.image.filename" class="thumb"
                          :src="getImageURL(question.image.url)"></b-card-img>
              <br>
              <a href="javascript:void(0);" @click="deleteFile">
                {{ $t('actions.delete') }}
              </a>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <vue-editor
              v-model="question.comment" 
              :placeholder="$t('views.placeholders.quiz.questionComment')">
            </vue-editor>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {Choice} from 'models/choice';
import { VueEditor } from 'vue2-editor';
import ImageInputMixin from 'mixins/ImageInputMixin';

export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return  {
      questionTitle: {
        type: 'text',
        key: 'title',
        value: '',
        label: this.$t('models.question.attributes.title'),
        required: true,
        valid: false,
        classes: 'col-12 py-0'
      },
      choiceTitle: {
        type: 'text',
        key: 'title',
        value: '',
        label: this.$t('models.question.attributes.title'),
        required: true,
        valid: false,
        classes: 'col-12 py-0'
      },
      invalidQuestion: '',
      questionState: null,
      fileName: '',
      file: null,
      maxSize: null
    };
  },
  methods: {
    addChoice() {
      this.question.choices.items.push(new Choice());
    },
    removeChoice(index) {
      const choice = this.question.choices.items[index];
      choice.destroy = true;

      this.question.choices.items.splice(index, 0, choice);
    },
    removeQuestion(){
      this.$emit('remove-question');
    }
  },
  components: { VueEditor },
  mixins: [ImageInputMixin]
};
</script>

<style scoped>
.custom__remove {
  cursor: pointer;
}

.checkbox {
  width: 100px;
}
</style>
